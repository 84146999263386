var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "context-title",
        {
          attrs: {
            title: _vm.title,
            passedActions: _vm.primaryTitleBarActions,
          },
        },
        [
          _c("template", { slot: "secondary" }, [
            _c(
              "div",
              { staticClass: "d-flex", staticStyle: { "margin-left": "auto" } },
              [
                _c(
                  "text-button",
                  {
                    staticClass: "block-xs--sm-left",
                    staticStyle: { "margin-right": "20px" },
                    attrs: { type: "delete" },
                    nativeOn: {
                      click: function ($event) {
                        return (() =>
                          (_vm.deleteConfirmationModal = true)).apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [_vm._v("Delete product")]
                ),
                _vm.hasPreviousItem()
                  ? _c(
                      "ActionButton",
                      {
                        attrs: {
                          type: "faded",
                          noIcon: true,
                          action: _vm.gotoPreviousItem,
                        },
                      },
                      [_vm._v("View Previous")]
                    )
                  : _vm._e(),
                _c(
                  "ActionButton",
                  { attrs: { type: "faded", noIcon: true, action: _vm.leave } },
                  [_vm._v("Close")]
                ),
                _vm.hasNextItem()
                  ? _c(
                      "ActionButton",
                      {
                        attrs: {
                          type: "faded",
                          noIcon: true,
                          action: _vm.gotoNextItem,
                        },
                      },
                      [_vm._v("View Next")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "v-container",
        { staticClass: "fill-height pa-3", attrs: { fluid: "" } },
        [
          _vm.upsellAddonItem &&
          _vm.upsellPositions &&
          !_vm.upsellAddonItem.exclusionary
            ? _c(
                "v-row",
                [
                  _vm._l(4, function (i) {
                    return [
                      _c("EditCard", {
                        key: i,
                        attrs: {
                          position: i,
                          items: _vm.itemsForPosition(i),
                          target: _vm.upsellAddonItem.targetName,
                          selectMoreItemsText: _vm.selectMoreItemsText,
                          emptyText: _vm.emptyText,
                        },
                        on: { onChange: _vm.handleChange },
                      }),
                    ]
                  }),
                ],
                2
              )
            : _vm.upsellAddonItem && _vm.upsellAddonItem.exclusionary
            ? _c(
                "v-row",
                [
                  [
                    _c("EditCard", {
                      key: "1",
                      attrs: {
                        position: 1,
                        exclusionary: true,
                        items: _vm.itemsForPosition(1),
                        target: _vm.upsellAddonItem.targetName,
                        selectMoreItemsText:
                          _vm.selectMoreExclusionaryItemsText,
                        emptyText: _vm.emptyExclusionaryText,
                      },
                      on: { onChange: _vm.handleChange },
                    }),
                  ],
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.navigationConfirmationModal
        ? _c(
            "EditingModal",
            { attrs: { size: "sm" } },
            [
              _c(
                "AlertDialog",
                {
                  attrs: {
                    title: "Unsaved Changes",
                    onExit: () => (_vm.navigationConfirmationModal = false),
                    actions: _vm.navigationConfirmationActions,
                  },
                },
                [
                  _vm._v(
                    "You have unsaved changes, would you like to save these changes?"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.deleteConfirmationModal
        ? _c(
            "EditingModal",
            { attrs: { size: "sm" } },
            [
              _c(
                "AlertDialog",
                {
                  attrs: {
                    title: "Confirm Delete",
                    onExit: () => (_vm.deleteConfirmationModal = false),
                    actions: _vm.deleteConfirmationActions,
                  },
                },
                [
                  _vm._v(
                    "Are you sure you want to delete all upsell items associated with this item?"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }