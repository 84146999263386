<template>
  <div>
    <context-title :title="title" :passedActions="primaryTitleBarActions">
      <template slot="secondary">
        <div class="d-flex" style="margin-left: auto">
          <text-button
            class="block-xs--sm-left"
            style="margin-right: 20px"
            type="delete"
            @click.native="() => (deleteConfirmationModal = true)"
            >Delete product</text-button
          >
          <ActionButton
            v-if="hasPreviousItem()"
            type="faded"
            :noIcon="true"
            :action="gotoPreviousItem"
            >View Previous</ActionButton
          >
          <ActionButton type="faded" :noIcon="true" :action="leave"
            >Close</ActionButton
          >

          <ActionButton
            v-if="hasNextItem()"
            type="faded"
            :noIcon="true"
            :action="gotoNextItem"
            >View Next</ActionButton
          >
        </div>
      </template>
    </context-title>
    <v-container class="fill-height pa-3" fluid>
      <v-row
        v-if="
          upsellAddonItem && upsellPositions && !upsellAddonItem.exclusionary
        "
      >
        <template v-for="i in 4">
          <EditCard
            :key="i"
            :position="i"
            :items="itemsForPosition(i)"
            :target="upsellAddonItem.targetName"
            :selectMoreItemsText="selectMoreItemsText"
            :emptyText="emptyText"
            @onChange="handleChange"
          />
        </template>
      </v-row>
      <v-row v-else-if="upsellAddonItem && upsellAddonItem.exclusionary">
        <template>
          <EditCard
            key="1"
            :position="1"
            :exclusionary="true"
            :items="itemsForPosition(1)"
            :target="upsellAddonItem.targetName"
            :selectMoreItemsText="selectMoreExclusionaryItemsText"
            :emptyText="emptyExclusionaryText"
            @onChange="handleChange"
          />
        </template>
      </v-row>
    </v-container>
    <EditingModal v-if="navigationConfirmationModal" size="sm">
      <AlertDialog
        title="Unsaved Changes"
        :onExit="() => (navigationConfirmationModal = false)"
        :actions="navigationConfirmationActions"
        >You have unsaved changes, would you like to save these
        changes?</AlertDialog
      >
    </EditingModal>
    <EditingModal v-if="deleteConfirmationModal" size="sm">
      <AlertDialog
        title="Confirm Delete"
        :onExit="() => (deleteConfirmationModal = false)"
        :actions="deleteConfirmationActions"
        >Are you sure you want to delete all upsell items associated with this
        item?</AlertDialog
      >
    </EditingModal>
  </div>
</template>

<script>
import ContextTitle from "components/context_title.vue";
import EditCard from "components/upsell/upsell_item_edit_card.vue";
import ActionButton from "components/action_button";
import TextButton from "components/text_button.vue";
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";

export default {
    name: "UpsellMenuItemEdit",
    data() {
        return {
            upsellPositions: undefined,
            dirtyUpsellPositions: undefined,
            deleteConfirmationModal: false,
            deleteConfirmationActions: [
                {
                    type: "remove-red",
                    display: "No",
                    run: () => (this.deleteConfirmationModal = false),
                },
                {
                    type: "proceed-green",
                    display: "Yes",
                    run: this.deleteItem,
                },
            ],
            pendingRoute: undefined,
            navigationConfirmationModal: false,
            navigationConfirmationActions: [
                {
                    type: "remove-red",
                    display: "No",
                    run: this.confirmNavigation,
                },
                {
                    type: "proceed-green",
                    display: "Yes",
                    run: () => {
                        this.save().then(() => this.leave());
                    },
                },
            ],
        };
    },
    props: {
        selectMoreItemsText: {
            type: String,
            required: true,
        },
        emptyText: {
            type: String,
            required: true,
        },
        upsellAddonItem: {
            type: Object,
            required: false,
        },
        allUpsellAddonItems: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        leaveRoute: {
            type: String,
            required: true,
        },
        editRoute: {
            type: String,
            required: true,
        },
        saveMethod: {
            type: Function,
            required: true,
        },
        deleteMethod: {
            type: Function,
            required: true,
        },
        emptyExclusionaryText: {
            type: String,
            required: true,
        },
        selectMoreExclusionaryItemsText: {
            type: String,
            required: true,
        },
    },
    components: {
        ContextTitle,
        EditCard,
        ActionButton,
        TextButton,
        AlertDialog,
        EditingModal,
    },
    computed: {
        primaryTitleBarActions() {
            if (!this.dirty || !this.valid) return [];

            const items = [];

            const previous = {
                type: "proceed-green",
                display: "Save & View Previous",
                run: () => {
                    this.save().then(() => this.gotoPreviousItem());
                },
            };
            const saveClose = {
                type: "proceed-green",
                display: "Save & Close",
                run: () => {
                    this.save().then(() => this.leave());
                },
            };

            const saveNext = {
                type: "next-green",
                display: "Save & View Next",
                run: () => {
                    this.save().then(() => {
                        this.gotoNextItem();
                    });
                },
            };

            items.push(saveClose);

            if (this.hasNextItem(this.productId)) {
                items.push(saveNext);
            }

            return items;
        },

        dirty() {
            return this.dirtyUpsellPositions != undefined;
        },
        upsellAddonIndex() {
            if (!this.allUpsellAddonItems.length) return false;
      
            return this.allUpsellAddonItems.findIndex(
                (el) => el.TargetID == `${this.$route.params.TargetID}`.replace('e','') && el.exclusionary == this.upsellAddonItem.exclusionary
            );
        },
        valid() {
            return (
                Object.entries(this.upsellPositions).flatMap(([position, items]) => {
                    if (
                        this.dirtyUpsellPositions != undefined &&
            this.dirtyUpsellPositions[`${position}`] != undefined
                    ) {
                        return this.dirtyUpsellPositions[`${position}`];
                    } else {
                        return items;
                    }
                }).length != 0
            );
        },
    },
    methods: {
        itemsForPosition(position) {
            if (this.upsellPositions === undefined) return [];
            return this.upsellPositions[`${position}`];
        },
        deleteItem() {
            this.deleteConfirmationModal = false;

            return this.deleteMethod().then(() => {
                this.dirtyUpsellPositions = undefined;
                this.leave();
            });
        },
        gotoNextItem() {
            if (!this.hasNextItem) return;
            let nextTarget = this.allUpsellAddonItems[this.upsellAddonIndex + 1];
            this.guardRouteAway({
                name: this.editRoute,
                params: {
                    TargetID: `${nextTarget.TargetID}${nextTarget.exclusionary ? 'e' : ''}`,
                },
            });
        },
        gotoPreviousItem() {
            if (!this.hasPreviousItem) return;
            let nextTarget = this.allUpsellAddonItems[this.upsellAddonIndex - 1];
            this.guardRouteAway({
                name: this.editRoute,
                params: {
                    TargetID: `${nextTarget.TargetID}${nextTarget.exclusionary ? 'e' : ''}`,
                },
            });
        },
        leave() {
            this.guardRouteAway({
                name: this.leaveRoute,
            });
        },
        hasNextItem() {
            if (
                this.upsellAddonIndex == -1 ||
        this.upsellAddonIndex + 1 >= this.allUpsellAddonItems.length
            )
                return false;
            return true;
        },
        hasPreviousItem() {
            if (this.upsellAddonIndex == -1 || this.upsellAddonIndex == 0)
                return false;
            return true;
        },
        guardRouteAway(route) {
            if (this.dirtyUpsellPositions === undefined) {
                this.$router.push(route);
                return;
            }

            this.pendingRoute = route;
            this.navigationConfirmationModal = true;
        },
        confirmNavigation() {
            this.navigationConfirmationModal = false;
            this.$router.push(this.pendingRoute);
            this.pendingRoute = undefined;
        },
        save() {
            return this.saveMethod(this.dirtyUpsellPositions).then(() => {
                this.dirtyUpsellPositions = undefined;
                return Promise.resolve(true);
            });
        },
        rebuildPositions() {
            if (this.upsellAddonItem == undefined) return;
            this.dirtyUpsellPositions = undefined;
            this.upsellPositions = {};

            for (let index = 0; index < 4; index++) {
                var upsellPosition = this.upsellAddonItem.items.filter(
                    (el) => el.Position == index + 1
                );
                upsellPosition.sort((a, b) => a.Order - b.Order);
                this.upsellPositions[`${index + 1}`] = upsellPosition;
            }
        },
        handleChange(position, items) {
            if (this.dirtyUpsellPositions === undefined) {
                this.dirtyUpsellPositions = {};
            }
            this.$set(this.dirtyUpsellPositions, position, items);
            this.$set(this.upsellPositions, position, items);
        },
    },
    watch: {
        upsellAddonItem(newValue, oldValue) {
            this.rebuildPositions();
        },
    },
};
</script>

<style>
</style>
